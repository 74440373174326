<template>
  <el-dialog
    :title="!dataForm.id ? $t('add') : $t('update')"
    :append-to-body="true"
    :modal-append-to-body="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="100px"
    >
      <el-form-item :label="$t('job.beanName')" prop="beanName">
        <el-input
          v-model="dataForm.beanName"
          :placeholder="$t('job.tip1')"
          maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('job.methodName')" prop="methodName">
        <el-input
          v-model="dataForm.methodName"
          :placeholder="$t('job.methodName')"
          maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('job.parameter')" prop="params">
        <el-input
          v-model="dataForm.params"
          :placeholder="$t('job.parameter')"
          maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('job.cronExpression')" prop="cronExpression">
        <el-input
          v-model="dataForm.cronExpression"
          :placeholder="$t('job.tip2')"
          maxlength="50"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('job.remark')" prop="remark">
        <el-input
          v-model="dataForm.remark"
          :placeholder="$t('job.remark')"
          maxlength="50"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" :disabled="btnDisabled"
        >{{$t('cancel')}}</el-button
      >
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        :disabled="btnDisabled"
        >{{ $t('confirm') }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getById , saveOrUpdate } from '@/api/job/schedule'
export default {
  data() {
    return {
      visible: false,
      btnDisabled: false,
      dataForm: {
        id: 0,
        beanName: "",
        methodName: "",
        params: "",
        cronExpression: "",
        remark: "",
        status: 0
      },
      dataRule: {
        beanName: [
          { required: true, message: this.$t('job.tip3'), trigger: "blur" }
        ],
        methodName: [
          { required: true, message: this.$t('job.tip4'), trigger: "blur" }
        ],
        cronExpression: [
          { required: true, message: this.$t('job.tip5'), trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    init(id) {
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
     
          getById(this.dataForm.id).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm.beanName = data.data.beanName;
              this.dataForm.methodName = data.data.methodName;
              this.dataForm.params = data.data.params;
              this.dataForm.cronExpression = data.data.cronExpression;
              this.dataForm.remark = data.data.remark;
              this.dataForm.status = data.data.status;
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          this.btnDisabled = true; 
          const data_ = {
              jobId: this.dataForm.id || undefined,
              beanName: this.dataForm.beanName,
              methodName: this.dataForm.methodName,
              params: this.dataForm.params,
              cronExpression: this.dataForm.cronExpression,
              remark: this.dataForm.remark,
              status: !this.dataForm.id ? undefined : this.dataForm.status
            }
          saveOrUpdate(data_).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: this.$t('success'),
                type: "success",
                duration: 500,
                onClose: () => {
                  this.visible = false;
                  this.btnDisabled = false;
                  this.$emit("refreshDataList");
                }
              });
            } else {
              this.btnDisabled = false;
              this.$message.error(data.msg);
            }
          });
        }
      });
    }
  }
};
</script>
