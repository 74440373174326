import http from '@/utils/httpRequest'

export function getList(params) {
  return http({
          url: http.adornUrl('/job/schedule/list'),
          method: 'get',
          params: params
        })
} 

export function deleteJob(ids = []) {
	return http({
            url: http.adornUrl("/job/schedule/delete"),
            method: "post",
            data: http.adornData(ids, false)
          })
}

// 暂停
export function pause(ids = []) { 
  return http({
            url: http.adornUrl("/job/schedule/pause"),
            method: "post",
            data: http.adornData(ids, false)
          })
}

// 恢复
export function resume(ids = []) { 
  return http({
            url: http.adornUrl("/job/schedule/resume"),
            method: "post",
            data: http.adornData(ids, false)
          })
}

// 立即执行
export function run(ids = []) { 
  return http({
            url: http.adornUrl("/job/schedule/run"),
            method: "post",
            data: http.adornData(ids, false)
          })
}

// 执行日志
export function logList(params) {
  return http({
        url: http.adornUrl("/job/scheduleLog/list"),
        method: "get",
        params: params
        // params: http.adornParams(...params)
      })
}

// 执行日志详细
export function getLogById(id) {
  return http({
        url: http.adornUrl(`/job/scheduleLog/info/${id}`),
        method: "get",
        params: http.adornParams()
      })
}

export function getById(id) {
	return http({
        url: http.adornUrl(`/job/schedule/info/${id}`),
        method: "get"
        })
}
 
export function saveOrUpdate(data) {
	return http({
            url: http.adornUrl(
              `/job/schedule/${!data.jobId ? "save" : "update"}`
            ),
            method: "post",
            data: data
        })
}